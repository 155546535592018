
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Info } from '@/types/expert'

@Component({
  components: { UploadFile },
  filters: {
    isShowFilters (value: number) {
      return value === 0 ? '否' : value === 1 ? '是' : ''
    }
  }
})
export default class ExpertDetail extends Vue {
  private info: Info = {
    expertName: '',
    positionName: '',
    expertIntroduce: '',
    honor: '',
    isShow: null,
    tagList: [],
    resource: null
  }

  private get title () {
    return this.$route.params.id ? '编辑' : '新增'
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.expert.expertDetail, {
      expertId: this.$route.params.id
    }).then(res => {
      this.info = res
    })
  }
}
